



































































import { Vue, Component, Prop, Watch } from "vue-property-decorator"
import Agency from "~/models/Agency"
import Agent from "~/models/Agent"
import AgentCard from "@/components/agents/AgentCard.vue"
import PromptAgentNewAgency from "@/components/modals/PromptAgentNewAgency.vue"
import { agents, Hit, Agent as AlgoliaAgent } from "~/config/algolia"

@Component({
  components: {
    AgentCard,
    PromptAgentNewAgency,
  },
})
export default class AgentPicker extends Vue {
  @Prop({ required: true }) agency!: Agency
  @Prop({ default: () => [] }) exclude!: number[] // ids of agents not to display
  @Prop({ default: null }) onPick!: ((agent: Agent) => void) | null
  @Prop({ default: 2 }) elevation!: number
  @Prop({ default: "Sélectionner" }) pickText!: string
  agents: Agent[] = []
  searchAllAgencies = false
  search = ""
  selectedAgent: Agent | null = null

  mounted() {
    this.findAgents()
  }

  @Watch("search")
  @Watch("searchAllAgencies")
  async findAgents() {
    const options = {
      filters:
        !this.searchAllAgencies && this.agency?.id
          ? `current_agency_id = ${this.agency.id}`
          : undefined,
      facetFilters: [[`individual:${false}`]],
    }
    const response = await agents.search(this.search, options)
    const hits = response.hits as Hit<AlgoliaAgent>[]
    this.agents = hits.map((hit) => Agent.fromAlgoliaHit(hit))
  }

  updateAgent(agent: Agent) {
    this.selectedAgent = agent
  }
  get filteredAgents(): Agent[] {
    return this.agents.filter(({ id }) => !id || !this.exclude.includes(id))
  }
  pickAgent() {
    if (
      this.selectedAgent?.agency_id &&
      this.selectedAgent?.agency_id !== this.agency.id
    ) {
      const prompt = this.$refs
        .agentNewAgencyAssociation as PromptAgentNewAgency
      const agent = this.selectedAgent
      if (agent) prompt.open()
    } else this.linkHouseToAgent()
  }
  linkHouseToAgent() {
    this.$emit("pick", this.selectedAgent)
    this.searchAllAgencies = false
  }
}
