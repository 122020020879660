











































































































































































import { Component, Vue, Prop } from "vue-property-decorator"
import House from "~/models/House"
import Agency from "~/models/Agency"
import Agent from "~/models/Agent"
import OcularAvatar from "~/components/OcularAvatar.vue"
import OcularIcon from "~/components/OcularIcon.vue"
import OcularRatingAdvise from "~/components/OcularRating/OcularRatingAdvise.vue"
import OcularPhone from "~/components/OcularPhone.vue"
import snackbar from "~/plugins/snackbar"
import HouseActionsTeam from "../house/HouseActionsTeam.vue"

@Component({
  components: {
    OcularAvatar,
    OcularIcon,
    OcularRatingAdvise,
    OcularPhone,
    OcularPrompt: () => import("~/components/OcularPrompt.vue"),
    CreateAgency: () => import("@/components/modals/CreateAgency.vue"),
    HouseCard: () => import("@/components/house/HouseCard/HouseCard.vue"),
    HouseActionsTeam: () => import("@/components/house/HouseActionsTeam.vue"),
    AgencyAds: () => import("@/components/agencies/AgencyAds.vue"),
    RatingByHomecatchers: () =>
      import("@/components/agencies/RatingByHomecatchers.vue"),
  },
})
export default class AgencyCard extends Vue {
  @Prop({ required: true }) agency!: Agency
  @Prop({ default: null }) house!: House | null // optional ; used to display and handle associated agents
  @Prop({ default: null }) onClick!: ((agencyId: number) => void) | null
  @Prop({ default: 2 }) elevation!: number
  @Prop({ default: "list" }) display!: "full" | "list"
  @Prop({ default: false }) loading!: boolean
  @Prop({ default: false }) loadingHouse!: boolean
  updatedAgency = new Agency()
  miscellaneousRowDisplay = false // when the container is large, display in row

  mounted() {
    this.checkMiscellaneousDisplay()
    addEventListener("resize", this.checkMiscellaneousDisplay)
  }
  destroyed() {
    removeEventListener("resize", this.checkMiscellaneousDisplay)
  }

  get events() {
    const events: Record<string, (...args: any[]) => void> = {}
    if (this.onClick) events.click = this.onClick
    return events
  }
  get miscellaneousElement() {
    return this.$refs.miscellaneous as HTMLElement
  }
  get actions_team() {
    if (this.agency.id) {
      return this.house?.actions_team?.filter(
        ({ agency_id }) => agency_id == this.agency.id
      )
    }
    return this.house?.actions_team
  }
  checkMiscellaneousDisplay() {
    if (!this.house || !this.miscellaneousElement) return
    this.miscellaneousRowDisplay = this.miscellaneousElement.clientWidth > 360
  }
  async onAgentCreated(agent: Agent) {
    if (!this.house) return
    if (!this.agency.agents) this.agency.agents = []
    agent.agency_name = this.agency.name
    this.agency.agents.push(agent)
    this.agency.agents_count == (this.agency.agents_count || 0) + 1
    await this.house.associateAgent({
      agency_id: this.agency.id || 0,
      agent_id: agent.id || 0,
      deal_id: this.house.deal_id || 0,
      previous_agency_id: this.agency.id || 0,
    })
    snackbar({
      color: "success",
      content: `L'agent ${agent.fullname} a correctement été créé et associé 👌`,
    })
  }
  associatedAdsCount() {
    if (!this.house) return "?"
    let count = 0
    this.house.history.forEach(
      ({ agency_id }) => agency_id == this.agency.id && count++
    )
    return count
  }
  openCreateAgencyDialog() {
    this.updatedAgency.assign(this.agency)
    // @ts-ignore
    this.$refs.createAgency.open()
  }
  onUpdateAgency() {
    this.agency.assign(this.updatedAgency)
    snackbar({ color: "success", content: `Agence mise à jour 👌` })
  }
  async removeAgencyAssociation() {
    const agencyIndex = this.house?.agencies?.findIndex(
      (agency) => agency.id == this.agency.id
    )
    if (agencyIndex != null) {
      await this.house?.removeAgencyAssociation({
        agency_id: this.agency.id || 0,
        deal_id: this.house.deal_id || 0,
      })
      snackbar({
        color: "success",
        content: `L'association a été supprimée avec succès 👌`,
      })
      this.$emit("closeAgencyCard")
    }
  }
}
