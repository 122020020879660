
























































import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import Agency from "~/models/Agency"
import Agent from "~/models/Agent"
import House from "~/models/House"
import AgencyCard from "@/components/agencies/AgencyCard.vue"
import AgentPicker from "@/components/agencies/AgentPicker.vue"
import snackbar from "~/plugins/snackbar"
import CreateAgent from "@/components/modals/CreateAgent.vue"

@Component({
  components: {
    AgencyCard,
    AgentPicker,
    CreateAgent,
    AgentCard: () => import("@/components/agents/AgentCard.vue"),
  },
})
export default class AgencySheet extends Vue {
  @Prop({ default: null }) house!: House | null
  @Prop({ default: false }) loading!: boolean // if the house is loading
  loadEditor = false
  agency = new Agency()
  fullAgentCards: number[] = []
  loadingAgency = false
  selectedAgent: Agent = new Agent()

  async created() {
    if (this.house || this.loading) {
      // first we try to load from the house (no additional api call)
      this.onHouseLoaded()
    } else if (!this.agency.id) {
      // else we fetch the agency from the api
      this.agency.id = this.agencyIdFromUrl()
      this.loadingAgency = true
      await this.agency.fetch()
      this.loadingAgency = false
    }
  }
  toggleFullAgentCard(agent: Agent) {
    if (!agent.id) return
    const index = this.fullAgentCards.indexOf(agent.id)
    if (~index) this.fullAgentCards.splice(index, 1)
    else this.fullAgentCards.push(agent.id)
  }
  agencyIdFromUrl() {
    return this.$route.params.agencyId ? +this.$route.params.agencyId : 0
  }
  async associateAgent(agent: Agent) {
    this.selectedAgent = agent
    if (!this.house) {
      // eslint-disable-next-line
      return console.warn("No house ; cannot associate agent")
    }
    if (!this.agency.id) {
      // eslint-disable-next-line
      return console.warn("The agency has no id:", this.agency)
    }
    if (!agent.id) {
      // eslint-disable-next-line
      return console.warn("The agent has no id:", agent)
    }
    const agentAgencyChanged = agent.agency_id != this.agency.id
    agent.fetch()
    await this.house.associateAgent({
      agency_id: this.agency.id,
      agent_id: agent.id,
      deal_id: this.house.deal_id,
      previous_agency_id: agent.agency_id,
    })
    agent.agency_id = this.agency.id
    agent.agency_name = this.agency.name
    this.agency.agents?.push(agent)
    snackbar({
      color: "success",
      content: `L'agent ${agent.fullname} a bien été associé au bien`,
    })
    const agentEditor = this.$refs.agentEditor as CreateAgent
    if (agentAgencyChanged) agentEditor.open()
  }

  async removeAgentAssociation(agentToUnassociate: Agent) {
    if (
      this.house == null ||
      this.house.agencies == null ||
      agentToUnassociate == null
    )
      return
    const agencyIndex = this.house.agencies.findIndex(
      (agency) => agency.id == agentToUnassociate.agency_id
    )
    if (agencyIndex != null) {
      const agentIndex = this.house.agencies[agencyIndex].agents?.findIndex(
        (agent) => agent.id == agentToUnassociate.id
      )
      if (agentIndex != null) {
        await this.house.removeAgentAssociation({
          agent_id: agentToUnassociate.id || 0,
          deal_id: this.house.deal_id || 0,
        })
        const id = this.agencyIdFromUrl()
        this.agency =
          this.house.agencies.find((agency) => agency.id == id) || this.agency
        snackbar({
          color: "success",
          content: `L'association a été supprimée avec succès 👌`,
        })
      }
    }
  }
  @Watch("house.agencies")
  onHouseLoaded() {
    if (this.house?.agencies && !this.agency.id) {
      const id = this.agencyIdFromUrl()
      this.agency =
        this.house.agencies.find((agency) => agency.id == id) || this.agency
    }
  }

  openEditor() {
    if (this.loadEditor) this.agentEditorLoaded()
    else this.loadEditor = true
  }
  agentEditorLoaded() {
    const editor = this.$refs.updateAgentDialog as CreateAgent
    editor.open()
  }
}
