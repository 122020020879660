






















import { Component, Vue, Prop } from "vue-property-decorator"
import OcularPrompt from "~/components/OcularPrompt.vue"
import Agent from "~/models/Agent"
import Agency from "~/models/Agency"

@Component({
  components: {
    OcularPrompt,
  },
})
export default class PromptAgentNewAgency extends Vue {
  @Prop({ default: null }) agent!: Agent
  @Prop({ default: null }) agency!: Agency

  open() {
    // @ts-ignore
    this.$refs.dialog.open = true
  }

  confirmAgencyAttachment() {
    this.$emit("confirm-agency-attachment", true)
  }

  cancel() {
    this.$emit("cancel-agency-attachment")
  }
}
